import { RootStackParamList } from "./types";

export const prependSlugToIds = <T extends keyof RootStackParamList>(
  routeName: T,
  routeParams: RootStackParamList[T]
): Record<string, unknown> => {
  const sluggedParams = { ...routeParams };

  if (
    routeName === "Podcast" ||
    routeName === "Episode" ||
    routeName === "Editor" ||
    routeName === "StudioEpisodeOverview" ||
    routeName === "StudioEpisodeAIContent" ||
    routeName === "StudioEpisodeVideo" ||
    routeName === "StudioAudiogramPreview" ||
    routeName === "StudioAudiogramEdit" ||
    routeName === "StudioAudiogramTrim"
  ) {
    const params = sluggedParams as
      | RootStackParamList["Podcast"]
      | RootStackParamList["Episode"]
      | RootStackParamList["Editor"]
      | RootStackParamList["StudioEpisodeOverview"]
      | RootStackParamList["StudioEpisodeAIContent"]
      | RootStackParamList["StudioEpisodeVideo"]
      | RootStackParamList["StudioAudiogramPreview"]
      | RootStackParamList["StudioAudiogramEdit"]
      | RootStackParamList["StudioAudiogramTrim"];

    if (params._slug_podcast && params.podcast_id) {
      params.podcast_id = `${params._slug_podcast}-${params.podcast_id}`;
    }
  }

  if (
    routeName === "Episode" ||
    routeName === "Editor" ||
    routeName === "StudioEpisodeOverview" ||
    routeName === "StudioEpisodeAIContent" ||
    routeName === "StudioEpisodeVideo" ||
    routeName === "StudioAudiogramPreview" ||
    routeName === "StudioAudiogramEdit" ||
    routeName === "StudioAudiogramTrim"
  ) {
    const params = sluggedParams as
      | RootStackParamList["Episode"]
      | RootStackParamList["Editor"]
      | RootStackParamList["StudioEpisodeOverview"]
      | RootStackParamList["StudioEpisodeAIContent"]
      | RootStackParamList["StudioEpisodeVideo"]
      | RootStackParamList["StudioAudiogramPreview"]
      | RootStackParamList["StudioAudiogramEdit"]
      | RootStackParamList["StudioAudiogramTrim"];

    if (params._slug_episode && params.episode_id) {
      params.episode_id = `${params._slug_episode}-${params.episode_id}`;
    }
  }

  if (routeName === "Speaker") {
    const params = sluggedParams as RootStackParamList["Speaker"];

    if (params._slug_speaker && params.speaker_id) {
      params.speaker_id = `${params._slug_speaker}-${params.speaker_id}`;
    }
  }

  return sluggedParams;
};

export const removeUnderscoredParams = (
  routeParams: object
): Record<string, unknown> => {
  if (!routeParams) {
    return routeParams;
  }

  const cleanedParams: Record<string, unknown> = {};

  for (const [name, value] of Object.entries(routeParams)) {
    if (!name.startsWith("_")) {
      cleanedParams[name] = value;
    }
  }

  return cleanedParams;
};

export const formatPageTitle = (title: string): string => {
  return `${title ? `${title} |` : ""} Musixmatch Podcasts`;
};

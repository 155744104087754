/* THIS FILE IS AUTOGENERATED. DO NOT CHANGE. */

export default (i18n: any) => {
  i18n.addResourceBundle(
    "ar",
    "common",
    require("./translations/ar-SA/common.json")
  );
  i18n.addResourceBundle(
    "ar",
    "languages",
    require("./translations/ar-SA/languages.json")
  );
  i18n.addResourceBundle(
    "ar",
    "profiling",
    require("./translations/ar-SA/profiling.json")
  );
  i18n.addResourceBundle(
    "bn",
    "common",
    require("./translations/bn-BD/common.json")
  );
  i18n.addResourceBundle(
    "bn",
    "languages",
    require("./translations/bn-BD/languages.json")
  );
  i18n.addResourceBundle(
    "bn",
    "profiling",
    require("./translations/bn-BD/profiling.json")
  );
  i18n.addResourceBundle(
    "cs",
    "common",
    require("./translations/cs-CZ/common.json")
  );
  i18n.addResourceBundle(
    "cs",
    "languages",
    require("./translations/cs-CZ/languages.json")
  );
  i18n.addResourceBundle(
    "cs",
    "profiling",
    require("./translations/cs-CZ/profiling.json")
  );
  i18n.addResourceBundle(
    "da",
    "common",
    require("./translations/da-DK/common.json")
  );
  i18n.addResourceBundle(
    "da",
    "languages",
    require("./translations/da-DK/languages.json")
  );
  i18n.addResourceBundle(
    "da",
    "profiling",
    require("./translations/da-DK/profiling.json")
  );
  i18n.addResourceBundle(
    "de",
    "common",
    require("./translations/de-DE/common.json")
  );
  i18n.addResourceBundle(
    "de",
    "languages",
    require("./translations/de-DE/languages.json")
  );
  i18n.addResourceBundle(
    "de",
    "profiling",
    require("./translations/de-DE/profiling.json")
  );
  i18n.addResourceBundle(
    "en",
    "common",
    require("./translations/en-US/common.json")
  );
  i18n.addResourceBundle(
    "en",
    "languages",
    require("./translations/en-US/languages.json")
  );
  i18n.addResourceBundle(
    "en",
    "profiling",
    require("./translations/en-US/profiling.json")
  );
  i18n.addResourceBundle(
    "es",
    "common",
    require("./translations/es-ES/common.json")
  );
  i18n.addResourceBundle(
    "es",
    "languages",
    require("./translations/es-ES/languages.json")
  );
  i18n.addResourceBundle(
    "es",
    "profiling",
    require("./translations/es-ES/profiling.json")
  );
  i18n.addResourceBundle(
    "fa",
    "common",
    require("./translations/fa-IR/common.json")
  );
  i18n.addResourceBundle(
    "fa",
    "languages",
    require("./translations/fa-IR/languages.json")
  );
  i18n.addResourceBundle(
    "fa",
    "profiling",
    require("./translations/fa-IR/profiling.json")
  );
  i18n.addResourceBundle(
    "fr",
    "common",
    require("./translations/fr-FR/common.json")
  );
  i18n.addResourceBundle(
    "fr",
    "languages",
    require("./translations/fr-FR/languages.json")
  );
  i18n.addResourceBundle(
    "fr",
    "profiling",
    require("./translations/fr-FR/profiling.json")
  );
  i18n.addResourceBundle(
    "hi",
    "common",
    require("./translations/hi-IN/common.json")
  );
  i18n.addResourceBundle(
    "hi",
    "languages",
    require("./translations/hi-IN/languages.json")
  );
  i18n.addResourceBundle(
    "hi",
    "profiling",
    require("./translations/hi-IN/profiling.json")
  );
  i18n.addResourceBundle(
    "id",
    "common",
    require("./translations/id-ID/common.json")
  );
  i18n.addResourceBundle(
    "id",
    "languages",
    require("./translations/id-ID/languages.json")
  );
  i18n.addResourceBundle(
    "id",
    "profiling",
    require("./translations/id-ID/profiling.json")
  );
  i18n.addResourceBundle(
    "it",
    "common",
    require("./translations/it-IT/common.json")
  );
  i18n.addResourceBundle(
    "it",
    "languages",
    require("./translations/it-IT/languages.json")
  );
  i18n.addResourceBundle(
    "it",
    "profiling",
    require("./translations/it-IT/profiling.json")
  );
  i18n.addResourceBundle(
    "ja",
    "common",
    require("./translations/ja-JP/common.json")
  );
  i18n.addResourceBundle(
    "ja",
    "languages",
    require("./translations/ja-JP/languages.json")
  );
  i18n.addResourceBundle(
    "ja",
    "profiling",
    require("./translations/ja-JP/profiling.json")
  );
  i18n.addResourceBundle(
    "ko",
    "common",
    require("./translations/ko-KR/common.json")
  );
  i18n.addResourceBundle(
    "ko",
    "languages",
    require("./translations/ko-KR/languages.json")
  );
  i18n.addResourceBundle(
    "ko",
    "profiling",
    require("./translations/ko-KR/profiling.json")
  );
  i18n.addResourceBundle(
    "nl",
    "common",
    require("./translations/nl-NL/common.json")
  );
  i18n.addResourceBundle(
    "nl",
    "languages",
    require("./translations/nl-NL/languages.json")
  );
  i18n.addResourceBundle(
    "nl",
    "profiling",
    require("./translations/nl-NL/profiling.json")
  );
  i18n.addResourceBundle(
    "pl",
    "common",
    require("./translations/pl-PL/common.json")
  );
  i18n.addResourceBundle(
    "pl",
    "languages",
    require("./translations/pl-PL/languages.json")
  );
  i18n.addResourceBundle(
    "pl",
    "profiling",
    require("./translations/pl-PL/profiling.json")
  );
  i18n.addResourceBundle(
    "pt",
    "common",
    require("./translations/pt-BR/common.json")
  );
  i18n.addResourceBundle(
    "pt",
    "languages",
    require("./translations/pt-BR/languages.json")
  );
  i18n.addResourceBundle(
    "pt",
    "profiling",
    require("./translations/pt-BR/profiling.json")
  );
  i18n.addResourceBundle(
    "ro",
    "common",
    require("./translations/ro-RO/common.json")
  );
  i18n.addResourceBundle(
    "ro",
    "languages",
    require("./translations/ro-RO/languages.json")
  );
  i18n.addResourceBundle(
    "ro",
    "profiling",
    require("./translations/ro-RO/profiling.json")
  );
  i18n.addResourceBundle(
    "ru",
    "common",
    require("./translations/ru-RU/common.json")
  );
  i18n.addResourceBundle(
    "ru",
    "languages",
    require("./translations/ru-RU/languages.json")
  );
  i18n.addResourceBundle(
    "ru",
    "profiling",
    require("./translations/ru-RU/profiling.json")
  );
  i18n.addResourceBundle(
    "sv",
    "common",
    require("./translations/sv-SE/common.json")
  );
  i18n.addResourceBundle(
    "sv",
    "languages",
    require("./translations/sv-SE/languages.json")
  );
  i18n.addResourceBundle(
    "sv",
    "profiling",
    require("./translations/sv-SE/profiling.json")
  );
  i18n.addResourceBundle(
    "ta",
    "common",
    require("./translations/ta-IN/common.json")
  );
  i18n.addResourceBundle(
    "ta",
    "languages",
    require("./translations/ta-IN/languages.json")
  );
  i18n.addResourceBundle(
    "ta",
    "profiling",
    require("./translations/ta-IN/profiling.json")
  );
  i18n.addResourceBundle(
    "th",
    "common",
    require("./translations/th-TH/common.json")
  );
  i18n.addResourceBundle(
    "th",
    "languages",
    require("./translations/th-TH/languages.json")
  );
  i18n.addResourceBundle(
    "th",
    "profiling",
    require("./translations/th-TH/profiling.json")
  );
  i18n.addResourceBundle(
    "tl",
    "common",
    require("./translations/tl-PH/common.json")
  );
  i18n.addResourceBundle(
    "tl",
    "languages",
    require("./translations/tl-PH/languages.json")
  );
  i18n.addResourceBundle(
    "tl",
    "profiling",
    require("./translations/tl-PH/profiling.json")
  );
  i18n.addResourceBundle(
    "tr",
    "common",
    require("./translations/tr-TR/common.json")
  );
  i18n.addResourceBundle(
    "tr",
    "languages",
    require("./translations/tr-TR/languages.json")
  );
  i18n.addResourceBundle(
    "tr",
    "profiling",
    require("./translations/tr-TR/profiling.json")
  );
  i18n.addResourceBundle(
    "vi",
    "common",
    require("./translations/vi-VN/common.json")
  );
  i18n.addResourceBundle(
    "vi",
    "languages",
    require("./translations/vi-VN/languages.json")
  );
  i18n.addResourceBundle(
    "vi",
    "profiling",
    require("./translations/vi-VN/profiling.json")
  );
  i18n.addResourceBundle(
    "zh",
    "common",
    require("./translations/zh-TW/common.json")
  );
  i18n.addResourceBundle(
    "zh",
    "languages",
    require("./translations/zh-TW/languages.json")
  );
  i18n.addResourceBundle(
    "zh",
    "profiling",
    require("./translations/zh-TW/profiling.json")
  );
};

import { useFeatureFlags } from "@mxmdev/podcasts-shared-native";
import { ActivityIndicator } from "@mxmdev/react-universal-components";
import { useAnalytics, useErrorTracking } from "@mxmdev/react-universal-core";
import { useAuth } from "@mxmdev/react-universal-core/auth-ssr/client";
import {
  NavigationContainer,
  LinkingOptions,
  getPathFromState,
  Route,
  PartialRoute,
} from "@mxmdev/react-universal-navigation/native";
import { useEffect, PropsWithChildren } from "react";

import { PlayerOverlay } from "../components/Player";
import {
  createURLConfigurationForSlugs,
  createURLConfigurationForUnderscores,
} from "../util/urlUtils";

import { navigationRef } from "./RootNavigation";
import { RootStackNavigtor } from "./RootStackNavigator";
import { RootStackParamList } from "./types";
import { prependSlugToIds, removeUnderscoredParams } from "./utils";

const RootNavigator = ({
  authSharedSignInEnabled,
}: PropsWithChildren<Props>) => {
  const { enableAudiogramDownload } = useFeatureFlags();

  const linking: LinkingOptions<RootStackParamList> = {
    /* The order here matters. */
    config: {
      screens: {
        ...(enableAudiogramDownload && {
          AudiogramDownload: {
            path: "audiogram/:request_id/download",
          },
        }),
        Editor: {
          path: "studio/podcast/:podcast_id/episode/:episode_id/transcription",
          ...createURLConfigurationForSlugs<"Editor">(
            "podcast_id",
            "episode_id"
          ),
        },
        Episode: {
          path: "podcast/:podcast_id/episode/:episode_id",
          ...createURLConfigurationForSlugs<"Episode">(
            "podcast_id",
            "episode_id"
          ),
        },
        Home: "",
        Podcast: {
          path: "podcast/:podcast_id",
          ...createURLConfigurationForSlugs<"Podcast">("podcast_id"),
        },
        Publisher: {
          path: "publisher/:publisher_id",
          ...createURLConfigurationForUnderscores<"Publisher">("publisher_id"),
        },
        PurchasePremium: {
          path: "purchase-premium",
        },
        Queue: {
          path: "queue",
        },
        Search: {
          path: "search/:type?/:q?/:id?",
          ...createURLConfigurationForUnderscores<"Search">("q"),
        },
        Speaker: {
          path: "speaker/:speaker_id",
          ...createURLConfigurationForSlugs<"Speaker">("speaker_id"),
        },
        Studio: {
          path: "studio",
        },
        StudioAudiogramEdit: {
          path: "studio/podcast/:podcast_id/episode/:episode_id/video/edit",
          ...createURLConfigurationForSlugs<"StudioAudiogramEdit">(
            "podcast_id",
            "episode_id"
          ),
        },
        StudioAudiogramPreview: {
          path: "studio/podcast/:podcast_id/episode/:episode_id/video/preview",
          ...createURLConfigurationForSlugs<"StudioAudiogramPreview">(
            "podcast_id",
            "episode_id"
          ),
        },
        StudioAudiogramTrim: {
          path: "studio/podcast/:podcast_id/episode/:episode_id/video/trim",
          ...createURLConfigurationForSlugs<"StudioAudiogramTrim">(
            "podcast_id",
            "episode_id"
          ),
        },
        StudioEpisodeAIContent: {
          path: "studio/podcast/:podcast_id/episode/:episode_id/ai",
          ...createURLConfigurationForSlugs<"StudioEpisodeAIContent">(
            "podcast_id",
            "episode_id"
          ),
        },
        StudioEpisodeOverview: {
          path: "studio/podcast/:podcast_id/episode/:episode_id",
          ...createURLConfigurationForSlugs<"StudioEpisodeOverview">(
            "podcast_id",
            "episode_id"
          ),
        },
        StudioEpisodeVideo: {
          path: "studio/podcast/:podcast_id/episode/:episode_id/video",
          ...createURLConfigurationForSlugs<"StudioEpisodeVideo">(
            "podcast_id",
            "episode_id"
          ),
        },
        TranscribeReferral: {
          path: "transcribe-referral",
        },
        Video: {
          path: "video/podcast/:podcast_id/episode/:episode_id",
        },
      },
    },

    getPathFromState: (state, options): string => {
      const routes = state.routes.map((route) => {
        const typedRoute = route as PartialRoute<
          Route<
            keyof RootStackParamList,
            RootStackParamList[keyof RootStackParamList]
          >
        >;

        const params = removeUnderscoredParams(
          prependSlugToIds(typedRoute.name, typedRoute.params)
        );

        return {
          ...typedRoute,
          params,
        };
      });

      const nextState = { ...state, routes };

      return getPathFromState(nextState, options);
    },
    prefixes: [],
  };

  const auth = useAuth();
  const setUserAnalytics = useAnalytics().setUser;
  const setUserError = useErrorTracking().setUser;

  useEffect(() => {
    if (auth.isLoggedIn && auth.user) {
      setUserAnalytics(auth.user);

      if (authSharedSignInEnabled) {
        setUserError(auth.user.id);
      }
    }
  }, [
    auth.isLoggedIn,
    auth.user,
    authSharedSignInEnabled,
    setUserAnalytics,
    setUserError,
  ]);

  return (
    <NavigationContainer
      documentTitle={{ enabled: false }}
      fallback={<ActivityIndicator />}
      linking={linking}
      ref={navigationRef}
    >
      <RootStackNavigtor />
      <PlayerOverlay />
    </NavigationContainer>
  );
};

interface Props {
  authSharedSignInEnabled: boolean;
}

export default RootNavigator;

import {
  DialogCustom,
  viewStyles,
  global,
  Text,
  DialogComponent,
  Button,
  imageStyles,
  textStyles,
  IconComponent,
  IconDownload,
  IconTopic,
  IconTranscript,
} from "@mxmdev/react-universal-components";
import { useMemo } from "react";
import { ColorValue, Image, View } from "react-native";

import { createResponsiveStyles, useResponsiveTheme } from "../../utils";

export const PurchasePremiumSuccessDialog: DialogComponent = (props) => {
  const { isSmallScreen, style, theme } = useResponsiveTheme(styles);

  const unlockedFeatures: Feature[] = useMemo(
    () => [
      {
        backgroundColor: theme.paletteYellow10,
        Icon: IconTranscript,
        iconBackgroundColor: theme.paletteYellow100,
        label: "Your new episode will now be transcribed automatically",
      },
      {
        backgroundColor: theme.paletteBlue10,
        Icon: IconDownload,
        iconBackgroundColor: theme.paletteBlue100,
        label: "You can now request Videoclip downloads",
      },
      {
        backgroundColor: theme.paletteGreen10,
        Icon: IconTopic,
        iconBackgroundColor: theme.paletteGreen100,
        label:
          "Your social posts, newsletter, and show notes are ready for you",
      },
    ],
    [theme]
  );

  return (
    <DialogCustom {...props} desktopWidth={550}>
      <Image
        source={{ uri: "/static/welcome_premium.png" }}
        style={style.image}
      />

      <View style={style.container}>
        <Text
          accessibilityRole="header"
          style={style.title}
          type={isSmallScreen ? "headingLarge" : "displayDefault"}
        >
          Welcome to Premium
        </Text>

        <Text color={theme.contentSecondary} type="bodySmall">
          Here's what you unlocked with Premium:
        </Text>
      </View>

      <View style={style.features}>
        {unlockedFeatures.map((feature, idx) => (
          <View
            key={idx}
            style={[
              style.feature,
              {
                backgroundColor: feature.backgroundColor,
                marginRight:
                  unlockedFeatures.length - 1 !== idx ? global.spacing * 2 : 0,
              },
            ]}
          >
            <feature.Icon
              color="white"
              size={20}
              style={[
                style.featureIcon,
                {
                  backgroundColor: feature.iconBackgroundColor,
                },
              ]}
            />

            <Text align="center" type="bodySmall">
              {feature.label}
            </Text>
          </View>
        ))}
      </View>

      <Button
        color="brand"
        onPress={props.onDismiss}
        style={style.continueButton}
        text="Continue"
      />
    </DialogCustom>
  );
};

type Feature = {
  Icon: IconComponent;
  label: string;
  iconBackgroundColor: ColorValue;
  backgroundColor: ColorValue;
};

const styles = createResponsiveStyles((theme, { isSmallScreen }) => ({
  ...viewStyles({
    container: { alignItems: "center", marginVertical: global.spacing * 4 },
    continueButton: {
      marginTop: global.spacing * 4,
      width: "100%",
    },
    feature: {
      alignItems: "center",
      borderRadius: global.radius.default,
      flex: 1,
      paddingHorizontal: global.spacing * 2,
      paddingVertical: global.spacing * 3,
    },
    featureIcon: {
      borderRadius: global.radius.extraLarge,
      marginBottom: global.spacing * 2,
      padding: global.spacing * 2,
    },
    features: { flexDirection: "row" },
  }),
  ...imageStyles({
    image: {
      alignSelf: "center",
      height: 135,
      marginTop: isSmallScreen ? global.spacing * 3 : 0,
      width: 130,
    },
  }),
  ...textStyles({
    title: { marginBottom: global.spacing * 2 },
  }),
}));

import { assertUnreachable } from "@mxmdev/podcasts-shared";
import { usePodcastsPlayerAnalytics } from "@mxmdev/podcasts-shared-native";
import { useMediaQuery } from "@mxmdev/react-universal-components";
import dynamic from "next/dynamic";
import { useCallback } from "react";

import { navigate, push } from "../../navigation/RootNavigation";
import { usePlayer } from "../../player";

import { useTranscriptionButtonState } from "./useTranscriptionButtonState";

const DesktopPlayer = dynamic(() => import("./Desktop/DesktopPlayer"));
const EditorPlayer = dynamic(() => import("./Editor/EditorPlayer"));
const MobilePlayer = dynamic(() => import("./Mobile/MobilePlayer"));

const PlayerOverlay = (): JSX.Element => {
  const { isLargeScreen } = useMediaQuery();

  const {
    currentEpisode,
    keepTranscriptFocused,
    playerProfile,
    setKeepTranscriptFocused,
  } = usePlayer();

  const transcriptionButtonState = useTranscriptionButtonState();

  const { logTranscriptionClicked } = usePodcastsPlayerAnalytics();

  const navigateToEpisode = useCallback((): void => {
    if (!currentEpisode) return;

    if (!keepTranscriptFocused) {
      setKeepTranscriptFocused(true);
    }

    navigate("Episode", {
      _label_override: currentEpisode.name,
      _scroll_to: "transcription",
      _slug_episode: currentEpisode.slug,
      _slug_podcast: currentEpisode.podcastSlug,
      episode_id: currentEpisode.id,
      podcast_id: currentEpisode.podcastId,
    });
  }, [currentEpisode, keepTranscriptFocused, setKeepTranscriptFocused]);

  const navigateToQueue = useCallback((): void => push("Queue"), []);

  const handleTranscriptionPress = useCallback((): void => {
    logTranscriptionClicked();

    switch (transcriptionButtonState) {
      case "go_to_transcript":
        return navigateToEpisode();
      case "unfocus_transcript":
        return setKeepTranscriptFocused(true);
      case "focus_transcript":
        return setKeepTranscriptFocused(false);
      case "not_available":
        return undefined;
      default:
        return assertUnreachable(transcriptionButtonState);
    }
  }, [
    logTranscriptionClicked,
    navigateToEpisode,
    setKeepTranscriptFocused,
    transcriptionButtonState,
  ]);

  if (!currentEpisode) {
    return <></>;
  }

  const renderPlayer = (): JSX.Element => {
    if (playerProfile === "editor") {
      return <EditorPlayer />;
    }

    if (isLargeScreen) {
      return (
        <DesktopPlayer
          episode={currentEpisode}
          onDetailsPress={navigateToEpisode}
          onQueuePress={navigateToQueue}
          onTranscriptionPress={handleTranscriptionPress}
        />
      );
    } else {
      return (
        <MobilePlayer
          episode={currentEpisode}
          onDetailsPress={navigateToEpisode}
          onQueuePress={navigateToQueue}
          onTranscriptionPress={handleTranscriptionPress}
        />
      );
    }
  };

  return renderPlayer();
};

export default PlayerOverlay;

import i18next, { i18n, InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { I18N_DEFAULT_LNG, I18N_DEFAULT_NS } from "./config";
import addResourceBundles from "./translations";
import { getDeviceLanguage } from "./utils";

type InstallFn = (i18nInstance: i18n) => void;
type Config = {
  defaultLanguage?: string;
  debug?: boolean;
  detectionOrder?: string[];
};

/**
 * @deprecated Please refer to the `react-universal-i18n` package.
 */
const initI18n = (installFn?: InstallFn, config?: Config) => {
  const debug = config?.debug ?? __DEV__;
  const lng = config?.defaultLanguage ?? getDeviceLanguage();
  const detectionOrder = config?.detectionOrder ?? ["navigator"];

  const i18nextOptions: InitOptions = {
    debug,
    defaultNS: I18N_DEFAULT_NS, // default namespace used if not passed to translation function t (needs no prefix on calling t)
    detection: {
      order: detectionOrder,
    },
    fallbackLng: I18N_DEFAULT_LNG,
    fallbackNS: I18N_DEFAULT_NS, // namespaces to lookup key if not found in given namespace
    lng,
    partialBundledLanguages: true,
    // TODO?: get namespace from native app (path in deep link)
    // ns: ["?"] // namespaces to load - apparently not needed for default and added resource bundles
    react: {
      useSuspense: false,
    },
    returnEmptyString: false, // because Crowdin integration adds empty strings for missing translations
  };

  // TODO: add a backend suitable for (React) native?
  i18next
    .use(LanguageDetector)
    .use(initReactI18next) // if not using I18nextProvider
    .init(i18nextOptions);

  addResourceBundles(i18next);
  installFn?.(i18next);

  return i18next;
};

export default initI18n;

import { usePlayer } from "../../player";

export type TranscriptionButtonState =
  | "not_available"
  | "go_to_transcript"
  | "focus_transcript"
  | "unfocus_transcript";

export const useTranscriptionButtonState = (): TranscriptionButtonState => {
  const {
    activeEpisodePageId,
    currentEpisode,
    keepTranscriptFocused,
    transcriptionState,
  } = usePlayer();

  if (
    currentEpisode &&
    transcriptionState.status === "loaded" &&
    (transcriptionState.syncState.status === "loaded" ||
      transcriptionState.syncState.status === "error")
  ) {
    if (activeEpisodePageId !== currentEpisode.id) {
      return "go_to_transcript";
    } else {
      if (keepTranscriptFocused === true) {
        return "focus_transcript";
      } else {
        return "unfocus_transcript";
      }
    }
  } else {
    return "not_available";
  }
};

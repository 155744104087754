import { useDialogContext } from "@mxmdev/react-universal-components";
import { useCallback } from "react";

import { PurchasePremiumSuccessDialog } from "./PurchasePremiumSuccessDialog";

export const usePurchasePremiumSuccessDialog = (): {
  openPurchasePremiumSuccessDialog: () => Promise<unknown>;
} => {
  const { openDialog } = useDialogContext();

  const openPurchasePremiumSuccessDialog = useCallback(async () => {
    return await openDialog(PurchasePremiumSuccessDialog);
  }, [openDialog]);

  return {
    openPurchasePremiumSuccessDialog,
  };
};

import {
  useFeatureFlags,
  usePodcastsPremiumAnalytics,
  usePurchasePremiumSuccessDialog,
} from "@mxmdev/podcasts-shared-native";
import { ThemeContext } from "@mxmdev/react-universal-components/styles/themes/themeUtils";
import { useNavigation } from "@mxmdev/react-universal-navigation/native";
import {
  StackNavigationProp,
  createStackNavigator,
} from "@mxmdev/react-universal-navigation/stack";
import dynamic from "next/dynamic";
import { useContext, useMemo } from "react";

import { usePlayer } from "../player";
import { isStudioRoute } from "../util/urlUtils";

import { RootStackParamList } from "./types";

const Stack = createStackNavigator<RootStackParamList>();

const AudiogramDownload = dynamic(
  () => import("../screens/AudiogramDownload/AudiogramDownload"),
  {
    ssr: false,
  }
);
const Editor = dynamic(() => import("../screens/Editor/Editor"));
const Episode = dynamic(() => import("../screens/Episode/Episode"));
const Home = dynamic(() => import("../screens/Home/Home"));
const Publisher = dynamic(() => import("../screens/Publisher/Publisher"));
const PurchasePremium = dynamic(
  () => import("../screens/PurchasePremium/PurchasePremium")
);
const Studio = dynamic(() => import("../screens/Studio/Studio"));
const Podcast = dynamic(() => import("../screens/Podcast/Podcast"));
const Queue = dynamic(() => import("../screens/Queue/Queue"));
const Search = dynamic(() => import("../screens/Search/Search"));
const StudioAudiogramEdit = dynamic(
  () => import("../screens/StudioAudiogramEdit/StudioAudiogramEdit"),
  {
    ssr: false,
  }
);
const StudioAudiogramPreview = dynamic(
  () => import("../screens/StudioAudiogramPreview/StudioAudiogramPreview"),
  {
    ssr: false,
  }
);
const StudioAudiogramTrim = dynamic(
  () => import("../screens/StudioAudiogramTrim/StudioAudiogramTrim"),
  {
    ssr: false,
  }
);
const StudioEpisodeAIContent = dynamic(
  () => import("../screens/StudioEpisodeAIContent/StudioEpisodeAIContent")
);
const StudioEpisodeOverview = dynamic(
  () => import("../screens/StudioEpisodeOverview/StudioEpisodeOverview")
);
const StudioEpisodeVideo = dynamic(
  () => import("../screens/StudioEpisodeVideo/StudioEpisodeVideo"),
  {
    ssr: false,
  }
);
const Video = dynamic(() => import("../screens/Video/Video"), {
  ssr: false,
});
const TranscribeReferral = dynamic(
  () => import("../screens/TranscribeReferral/TranscribeReferral")
);
const Speaker = dynamic(() => import("../screens/Speaker/Speaker"));

export const RootStackNavigtor = (): JSX.Element => {
  const { currentEpisode, setEpisodes } = usePlayer();
  const { enableAudiogramDownload } = useFeatureFlags();
  const { openPurchasePremiumSuccessDialog } =
    usePurchasePremiumSuccessDialog();
  const { setParams } =
    useNavigation<StackNavigationProp<RootStackParamList>>();
  const { logPurchasePremiumCompleted } = usePodcastsPremiumAnalytics();

  const { scheme, setScheme } = useContext(ThemeContext);

  const screenOptions = useMemo(
    () => ({
      cardStyle: {
        flex: 1,
      },
      headerShown: false,
      // TODO: disabling the keyboard handling is needed to keep focus on the search bar
      // while changing screen on web, but how does it impact other platforms?
      keyboardHandlingEnabled: false,
    }),
    []
  );

  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenListeners={({ route }): { state: () => void } => ({
        state: (): void => {
          const routeName = route.name;

          // Set the dark theme in the /video page
          if (routeName === "Video" && scheme === "light") {
            setScheme("dark");
          } else if (routeName !== "Video" && scheme === "dark") {
            setScheme("light");
          }

          // Hide player when navigating to a studio route
          if (isStudioRoute(routeName) && currentEpisode) {
            setEpisodes([]);
          }

          // Show purchase premium success dialog
          if (route.params?.purchase_premium_success === "true") {
            const featureRef = route.params?.purchase_premium_feature_ref;
            const priceId = route.params?.purchase_premium_price_id;

            if (priceId !== undefined) {
              logPurchasePremiumCompleted(priceId, featureRef);
            }

            openPurchasePremiumSuccessDialog().finally(() => {
              setParams({
                purchase_premium_feature_ref: undefined,
                purchase_premium_price_id: undefined,
                purchase_premium_success: undefined,
              });
            });
          }
        },
      })}
      screenOptions={screenOptions}
    >
      <Stack.Screen
        component={Home}
        name="Home"
        options={{ title: "Discover" }}
      />
      <Stack.Screen component={Podcast} name="Podcast" />
      <Stack.Screen component={Publisher} name="Publisher" />
      <Stack.Screen component={PurchasePremium} name="PurchasePremium" />
      <Stack.Screen component={Episode} name="Episode" />
      <Stack.Screen
        component={StudioEpisodeAIContent}
        name="StudioEpisodeAIContent"
      />
      <Stack.Screen
        component={StudioEpisodeOverview}
        name="StudioEpisodeOverview"
      />
      <Stack.Screen component={StudioEpisodeVideo} name="StudioEpisodeVideo" />
      {enableAudiogramDownload && (
        <Stack.Screen component={AudiogramDownload} name="AudiogramDownload" />
      )}
      <Stack.Screen
        component={StudioAudiogramPreview}
        name="StudioAudiogramPreview"
      />
      <Stack.Screen
        component={StudioAudiogramEdit}
        name="StudioAudiogramEdit"
      />
      <Stack.Screen
        component={StudioAudiogramTrim}
        name="StudioAudiogramTrim"
      />
      <Stack.Screen component={Search} name="Search" />
      <Stack.Screen component={Queue} name="Queue" />
      <Stack.Screen component={Editor} name="Editor" />
      <Stack.Screen component={TranscribeReferral} name="TranscribeReferral" />
      <Stack.Screen component={Video} name="Video" />
      <Stack.Screen component={Studio} name="Studio" />
      <Stack.Screen component={Speaker} name="Speaker" />
    </Stack.Navigator>
  );
};

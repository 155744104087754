/* THIS FILE IS AUTOGENERATED. DO NOT CHANGE. */
import { I18N } from "@mxmdev/react-universal-core";

export default (i18n: I18N): void => {
  i18n.addResourceBundle(
    "ar",
    "podcasts",
    require("./translations/ar-SA/podcasts.json")
  );
  i18n.addResourceBundle(
    "bn",
    "podcasts",
    require("./translations/bn-BD/podcasts.json")
  );
  i18n.addResourceBundle(
    "cs",
    "podcasts",
    require("./translations/cs-CZ/podcasts.json")
  );
  i18n.addResourceBundle(
    "da",
    "podcasts",
    require("./translations/da-DK/podcasts.json")
  );
  i18n.addResourceBundle(
    "de",
    "podcasts",
    require("./translations/de-DE/podcasts.json")
  );
  i18n.addResourceBundle(
    "en",
    "podcasts",
    require("./translations/en-US/podcasts.json")
  );
  i18n.addResourceBundle(
    "es",
    "podcasts",
    require("./translations/es-ES/podcasts.json")
  );
  i18n.addResourceBundle(
    "fa",
    "podcasts",
    require("./translations/fa-IR/podcasts.json")
  );
  i18n.addResourceBundle(
    "fr",
    "podcasts",
    require("./translations/fr-FR/podcasts.json")
  );
  i18n.addResourceBundle(
    "hi",
    "podcasts",
    require("./translations/hi-IN/podcasts.json")
  );
  i18n.addResourceBundle(
    "id",
    "podcasts",
    require("./translations/id-ID/podcasts.json")
  );
  i18n.addResourceBundle(
    "it",
    "podcasts",
    require("./translations/it-IT/podcasts.json")
  );
  i18n.addResourceBundle(
    "ja",
    "podcasts",
    require("./translations/ja-JP/podcasts.json")
  );
  i18n.addResourceBundle(
    "ko",
    "podcasts",
    require("./translations/ko-KR/podcasts.json")
  );
  i18n.addResourceBundle(
    "nl",
    "podcasts",
    require("./translations/nl-NL/podcasts.json")
  );
  i18n.addResourceBundle(
    "pl",
    "podcasts",
    require("./translations/pl-PL/podcasts.json")
  );
  i18n.addResourceBundle(
    "pt",
    "podcasts",
    require("./translations/pt-BR/podcasts.json")
  );
  i18n.addResourceBundle(
    "ro",
    "podcasts",
    require("./translations/ro-RO/podcasts.json")
  );
  i18n.addResourceBundle(
    "ru",
    "podcasts",
    require("./translations/ru-RU/podcasts.json")
  );
  i18n.addResourceBundle(
    "sv",
    "podcasts",
    require("./translations/sv-SE/podcasts.json")
  );
  i18n.addResourceBundle(
    "ta",
    "podcasts",
    require("./translations/ta-IN/podcasts.json")
  );
  i18n.addResourceBundle(
    "th",
    "podcasts",
    require("./translations/th-TH/podcasts.json")
  );
  i18n.addResourceBundle(
    "tl",
    "podcasts",
    require("./translations/tl-PH/podcasts.json")
  );
  i18n.addResourceBundle(
    "tr",
    "podcasts",
    require("./translations/tr-TR/podcasts.json")
  );
  i18n.addResourceBundle(
    "vi",
    "podcasts",
    require("./translations/vi-VN/podcasts.json")
  );
  i18n.addResourceBundle(
    "zh",
    "podcasts",
    require("./translations/zh-TW/podcasts.json")
  );
};

import { canUseDOM } from "@mxmdev/react-universal-core";
import { ServerContainer } from "@mxmdev/react-universal-navigation/native";
import { ComponentProps, PropsWithChildren } from "react";

const ConditionalServerContainer = ({
  children,
  location,
}: PropsWithChildren<Props>): JSX.Element =>
  !canUseDOM ? (
    <ServerContainer location={location}>{children}</ServerContainer>
  ) : (
    <>{children}</>
  );

interface Props {
  location: ComponentProps<typeof ServerContainer>["location"];
}

export default ConditionalServerContainer;

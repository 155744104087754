import { removeUndefined } from "@mxmdev/podcasts-shared";
import {
  getServerSideAuthProps,
  NextPageWithAuth,
  initI18n,
} from "@mxmdev/react-universal-core";
import { Redirect } from "next";
import Head from "next/head";
import { ComponentProps } from "react";

import translations from "../i18n/translations";
import {
  API_ORIGIN,
  APP_ID,
  FF_ENABLE_SEO,
  AUTH_SHARED_SIGN_IN_ENABLED,
  FF_ENABLE_SSR,
} from "../processEnv";
import ConditionalServerContainer from "../src/components/ConditionalServerContainer";
import { fetchEntitySearchGet } from "../src/data-fetching/server/entity";
import { fetchEpisodeData } from "../src/data-fetching/server/episode";
import { fetchPodcastData } from "../src/data-fetching/server/podcast";
import { fetchSpeakerData } from "../src/data-fetching/server/speaker";
import RootNavigator from "../src/navigation/RootNavigator";
import {
  getEpisodeSEOProps,
  getHomeProps,
  getPodcastSEOProps,
  getPublisherProps,
  getSearchProps,
  getTopicSearchProps,
  getUserAgentProps,
  SEOProps,
} from "../src/seo";
import { getSpeakerSEOProps } from "../src/seo/speaker";
import { getEpisodeSSRProps } from "../src/ssr/episode";
import { getPodcastSSRProps } from "../src/ssr/podcast";
import { getSpeakerSSRProps } from "../src/ssr/speaker";
import { SSRProps } from "../src/ssr/types";
import { stringifyUnderscoredURLComponent } from "../src/util/urlUtils";

const podcastEpisodeUrl = /^\/podcast\/(.*-)*(\w+)\/episode\/(.*-)*(\w+)$/;
const podcastUrl = /^\/podcast\/(.*-)*(\w+)$/;
const topicSearchUrl = /^\/search\/topic\/(.+)\/(.+)$/;
const searchTopicNameUrl = /^\/search\/topic\/(.+)$/;
const publisherUrl = /^\/publisher\/(.+)$/;
const speakerUrl = /^\/speaker\/(.*-)*(\w+)$/;

interface IndexProps {
  seo: SEOProps;
  ssr: SSRProps | null;
  location: ComponentProps<typeof ConditionalServerContainer>["location"];
  isBot: boolean;
  userAgent: string | undefined;
}

export const i18n = initI18n(translations);

const Index: NextPageWithAuth<IndexProps> = ({ location, seo }) => (
  <ConditionalServerContainer location={location}>
    <Head>
      {seo.schemas?.map((schema, idx) => (
        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema, null, 2),
          }}
          key={idx}
          type="application/ld+json"
        />
      ))}
    </Head>
    <RootNavigator
      authSharedSignInEnabled={AUTH_SHARED_SIGN_IN_ENABLED === "true"}
    />
  </ConditionalServerContainer>
);

const getServerProps = async (
  url: string,
  params: string
): Promise<{ seo: SEOProps; ssr: SSRProps | null; redirect?: Redirect }> => {
  if (url === "/") {
    return { seo: getHomeProps(), ssr: null };
  }

  if (url === "/search") {
    return { seo: getSearchProps(), ssr: null };
  }

  const matchEpisodeUrl = url.match(podcastEpisodeUrl);

  if (matchEpisodeUrl) {
    const podcastIdLowercase = matchEpisodeUrl[2];
    const episodeIdLowercase = matchEpisodeUrl[4];

    const podcastId = podcastIdLowercase.toUpperCase();
    const episodeId = episodeIdLowercase.toUpperCase();

    const timeMatch = params?.match(/time=(\d+(?:[.]\d+)?)/);
    const timeStr = timeMatch?.[1];
    const time = timeStr ? parseFloat(timeStr) : undefined;

    const episodeData = await fetchEpisodeData(episodeId, podcastId);

    if (episodeData) {
      return {
        seo: await getEpisodeSEOProps(i18n, episodeData, time),
        ssr: getEpisodeSSRProps(episodeId, episodeData),
      };
    }
  }

  const matchPodcastUrl = url.match(podcastUrl);

  if (matchPodcastUrl) {
    const podcastIdLowercase = matchPodcastUrl[2];
    const podcastId = podcastIdLowercase.toUpperCase();

    const podcastData = await fetchPodcastData(podcastId);

    if (podcastData) {
      return {
        seo: getPodcastSEOProps(i18n, podcastData),
        ssr: getPodcastSSRProps(podcastId, podcastData),
      };
    }
  }

  const matchTopicSearchUrl = url.match(topicSearchUrl);

  if (matchTopicSearchUrl) {
    const [_, _q_entity, q_wikidata_id] = matchTopicSearchUrl;

    return {
      seo: await getTopicSearchProps(q_wikidata_id),
      ssr: null,
    };
  }

  const matchSearchTopicNameUrl = url.match(searchTopicNameUrl);

  if (matchSearchTopicNameUrl) {
    const [_, q_entity] = matchSearchTopicNameUrl;

    const entitySearchRes = await fetchEntitySearchGet({
      topicName: q_entity,
    });

    const topic = entitySearchRes.success
      ? entitySearchRes.result[0]
      : undefined;

    const underscoredEntityName = encodeURIComponent(
      stringifyUnderscoredURLComponent(decodeURIComponent(q_entity))
    );

    return {
      redirect: {
        destination:
          topic?.id !== undefined
            ? `/search/topic/${underscoredEntityName}/${topic.id}`
            : `/search/raw/${underscoredEntityName}`,
        permanent: true,
      },
      seo: { noindex: true },
      ssr: null,
    };
  }

  const matchPublisherUrl = url.match(publisherUrl);

  if (matchPublisherUrl) {
    const [_, publisherName] = matchPublisherUrl;

    return { seo: await getPublisherProps(publisherName), ssr: null };
  }

  const matchSpeakerUrl = url.match(speakerUrl);

  if (matchSpeakerUrl) {
    const speakerIdLowercase = matchSpeakerUrl[2];
    const speakerId = speakerIdLowercase.toUpperCase();

    const speakerData = await fetchSpeakerData(speakerId);

    if (speakerData) {
      return {
        seo: getSpeakerSEOProps(speakerData),
        ssr: getSpeakerSSRProps(speakerId, speakerData),
      };
    }
  }

  return { seo: { noindex: true }, ssr: null };
};

export const getServerSideProps = getServerSideAuthProps<IndexProps>(
  async (ctx, auth) => {
    const [url, params] = ctx.resolvedUrl.split("?");

    const userAgentProps = getUserAgentProps(ctx);

    const [pathname = "", search = ""] = ctx.req.url
      ? ctx.req.url?.split("?")
      : [];

    const location = {
      pathname,
      search: "?" + search,
    };

    const isSeoEnabled = FF_ENABLE_SEO?.toLocaleLowerCase() === "true";
    const isSsrEnabled = FF_ENABLE_SSR?.toLocaleLowerCase() === "true";

    const serverProps =
      isSeoEnabled || isSsrEnabled
        ? await getServerProps(url, params)
        : undefined;

    const seo =
      FF_ENABLE_SEO?.toLocaleLowerCase() === "true" && serverProps?.seo
        ? removeUndefined(serverProps.seo)
        : { noindex: true };

    const ssr =
      FF_ENABLE_SSR?.toLocaleLowerCase() === "true" && serverProps?.ssr
        ? removeUndefined(serverProps.ssr)
        : null;

    return {
      props: {
        auth,
        location,
        seo,
        ssr,
        ...userAgentProps,
      },
      redirect: serverProps?.redirect,
    };
  },
  {
    apiOrigin: API_ORIGIN!,
    appId: APP_ID!,
    enabled: AUTH_SHARED_SIGN_IN_ENABLED === "true",
    forceSignIn: false,
  }
);

export default Index;

import { useAnalytics } from "@mxmdev/react-universal-core";
import { useCallback } from "react";

export const usePodcastsPremiumAnalytics = () => {
  const { logEvent } = useAnalytics();

  const logPremiumFeatureClicked = useCallback(
    (
      type:
        | "audiogram"
        | "show_notes"
        | "social_posts"
        | "newsletter"
        | "transcription_podcast_page"
        | "transcription_studio_overview"
        | "transcription_episode_page"
        | "transcription_studio"
    ) => {
      logEvent("premium_feature_clicked", {
        type,
      });
    },
    [logEvent]
  );

  const logPurchasePremiumShowed = useCallback(
    (priceId: string, type: string | undefined) =>
      logEvent("purchase_premium_showed", {
        price_id: priceId,
        type,
      }),
    [logEvent]
  );

  const logPurchasePremiumCompleted = useCallback(
    (priceId: string, type: string | undefined) =>
      logEvent("purchase_premium_completed", {
        price_id: priceId,
        type,
      }),
    [logEvent]
  );

  return {
    logPremiumFeatureClicked,
    logPurchasePremiumCompleted,
    logPurchasePremiumShowed,
  };
};
